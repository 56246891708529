<template>
  <div>
    <Header/>
    <router-view style="background-color: #F8F8FA"></router-view>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "TherapistView",
  components: {Footer, Header},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
</style>
