<template>
  <div class="head-line">
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="header">
      <router-link to="/therapist/patientsManage">
        <img src="@/assets/images/IA/img_nav_logo.png" alt="logo" class="header-logo ">
      </router-link>
      <div class="user-info-container">
        <div class="level">치료사</div>
        <p class="user-name" v-if="name != null">{{ name }}
          <strong>님 반갑습니다.</strong>
        </p>
        <p class="divide">I</p>
        <router-link to="/therapist/myPage">
          <p>마이페이지</p>
        </router-link>
        <p class="divide">I</p>
        <p @click="isShowModal = true">
          로그아웃
        </p>
      </div>
    </div>
    <LogoutDialog v-if="isShowModal" @callBackModal="callBackModal"/>
  </div>
</template>

<script>
import {firebase} from "@/firebase/firebaseConfig";
import LogoutDialog from "@/views/therapist/myPage/LogoutDialog";

export default {
  name: "Header",
  components: {LogoutDialog},
  data() {
    return {
      name: '',
      isShowModal: false
    }
  },
  mounted() {
    this.isLoginUser()
  },
  methods: {
    isLoginUser() {
      if (this.$store.state.user == null) {
        alert('로그인 해야해')
      } else {
        this.name = this.$store.state.user.displayName
      }
    },
    callBackModal: function (index) {
      const self = this;
      if (index === 1) {
        self.isShowModal = false
      } else {
        self.isShowModal = false
        const logoutTime = new Date().getTime();
        localStorage.setItem('logoutTime', logoutTime.toString());
        firebase.auth().signOut()
            .then(() => {
              self.$router.replace('/');
            })
            // .catch((error) => {
            //   console.log(error);
            // });
      }
    },
  }
}
</script>

<style scoped>

.head-line {
  border-bottom: 1px solid #E1E3E6;
}

.header {
  background: #FFFFFF;
  height: 80px;
  padding: 0 25px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
}

.header-logo {
  width: 190px;
  height: 48px;
}

.header p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  color: #000000;
  margin: 0;
  cursor: pointer;
}

.user-name {
  cursor: default !important;
}

.header strong {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #999999;
  margin: 0;
}

.user-info-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
}

.divide {
  color: #E1E3E6 !important;
  width: 13px;
  text-align: center;
  margin: 0 10px !important;
}

.level {
  width: 49px;
  height: 24px;
  background: #FFFFFF;
  border: 2px solid #0069CA;
  box-sizing: border-box;
  border-radius: 100px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #0069CA;
  text-align: center;
  padding-top: 1px;
  margin-right: 5px;
}

</style>
